import React from "react";
import { usageReducer } from "../reducers/usageReducer";

export const UsageContext = React.createContext();

const initialState = {
    total_requests: false,
    total_sheet_apis: true,
};

const UsageContextProvider = (props) => {
    const [usage_state, usage_dispatch] = React.useReducer(
        usageReducer,
        initialState
    );

    return (
        <UsageContext.Provider value={{ usage_state, usage_dispatch }}>
            {props.children}
        </UsageContext.Provider>
    );
};

export default UsageContextProvider;
