import React from "react";
import axios from "axios";
import { SpreadsheetContext } from "../../contexts/SpreadsheetsContextProvider";
import "./style.css";
import googlesheet_icon from "../../assets/google-sheets.png";
import * as Icon from "react-feather";
import { Loader } from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { spreadsheetsReducer } from "../../reducers/SpreadSheetsReducer";
import { Constants } from "../../constants";


const Spreadsheets = () => {
    const navigate = useNavigate();
    const [isEmpty, setIsEmpty] = React.useState(false);
    const [loading, controlLoading] = React.useState(false);


    const { spreadsheet_state, spreadsheet_dispatch } = React.useContext(SpreadsheetContext);

    const retriveSheetAPIs = async () => {

        try {
            const response = await axios.get(
                `${Constants.apiURL}/api/v1/retrieve-spreadsheet-apis`,
                {
                    withCredentials: true,
                }
            );



            // Handle the response data as needed
            console.log("Response:", response.data);
            if (response.data.length === 0) {
                setIsEmpty(true);
            }
            spreadsheet_dispatch({ type: "FETCH_SPREADSHEETS", payload: response.data });
        } catch (error) {
            // Handle errors, such as network issues or server errors
            console.error("Error:", error);

        }
    };

    const copyToClipboard = async (textToCopy, id) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            //setIsCopied(true);
            const newSpreadsheets = spreadsheet_state.spreadsheets.map(spreadsheet => {
                return spreadsheet.id === id ? { ...spreadsheet, linkCopied: true } : spreadsheet;
            });

            spreadsheet_dispatch({ type: "FETCH_SPREADSHEETS", payload: newSpreadsheets });

        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    React.useEffect(() => {
        retriveSheetAPIs();
    }, []);

    return (
        <>
            <div className="dashboard-page-wrapper">
                <h1 className="dashboard-headline">Your Spreadsheets</h1>
                {
                    isEmpty ? <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="is-empty-message">You haven't created any spreadsheet API yet</span>
                        <div style={{ width: 350, display: "flex", alignItems: "center", justifyContent: "center" }}><button onClick={() => navigate("/dashboard/add-spreadsheet")} className="is-empty-button">Create Spreadsheet API</button></div>
                    </div> :
                        spreadsheet_state.spreadsheets.length == 0 ? <Loader /> :
                            spreadsheet_state.spreadsheets.map(spreadsheet =>
                                <div className="list-item-wrapper">
                                    <div className="list-item-header-text-wrapper">
                                        <img src={googlesheet_icon} />
                                        <span className="list-item-header-text">
                                            {spreadsheet.spreadsheet_title}
                                        </span>
                                    </div>

                                    <div className="api-url-wrapper">
                                        <span className="api-url-title">API URL</span>
                                        <span className="api-url-link-text"><a href={`${spreadsheet.spreadsheet_api_url}`}>{spreadsheet.spreadsheet_api_url}</a></span>
                                        <button onClick={() => copyToClipboard(spreadsheet.spreadsheet_api_url, spreadsheet.id)} className="copy-link-button"> <Icon.Link2 size={15} /> <span style={{ marginLeft: 10 }}>Copy</span></button>
                                        {spreadsheet.linkCopied === true && (
                                            <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                                                Link Copied!
                                            </div>
                                        )}
                                    </div>

                                </div>
                            )

                }
            </div>

        </>
    );
};


export default Spreadsheets;