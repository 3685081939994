import React from "react";
import { SheetsURLForm } from "../../components/sheets-url-form/SheetsURLForm";
import { BenefitsSection } from "../../sections/benefits-section/BenefitsSection";
import { HeroSection } from "../../sections/herosection/HeroSection";
import { IllustrationSection } from "../../sections/illustration-section/IllustrationSection";
import Footer from "../../components/footer/Footer";
import { ExampleSection } from "../../sections/example-section/ExampleSection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <IllustrationSection />
      <BenefitsSection />
      <ExampleSection />
      <Footer />
    </>
  );
};

export default Home;
