import React from "react";
import { ShimmerContentBlock } from "react-shimmer-effects";


export const Loader = () => {
    return (
        <>
            <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={300}
                thumbnailHeight={200}
            />
            <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={300}
                thumbnailHeight={200}
            />
            <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={300}
                thumbnailHeight={200}
            />
        </>
    );
};
