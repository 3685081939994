import React from "react";
import axios from "axios";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Constants } from "../../constants";


const CreateSpreadSheetAPI = () => {

    const navigate = useNavigate();

    const [sheetURL, setSheetUrl] = React.useState("");
    const [loading, controlLoading] = React.useState(false);


    const handleSheetURL = (e) => {
        setSheetUrl(e.target.value);
    };

    //Function to validate googlesheet url
    const isGoogleSheetUrl = (url) => {
        // Regular expression to match Google Sheets URLs
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+\/edit/;

        if (regex.test(url)) {
            return true; // It's a Google Sheets URL
        } else {
            return false;
        }
    };

    const generateAPI = async (e) => {
        e.preventDefault();

        if (isGoogleSheetUrl(sheetURL)) {
            const data = {
                spreadsheet_url: sheetURL,
            };
            try {
                controlLoading(true);
                const response = await axios.post(
                    `${Constants.apiURL}/api/v1/generate-sheet-api`,
                    data,
                    {
                        withCredentials: true,
                    }
                );

                navigate("/dashboard/spreadsheets");
                controlLoading(false);

                // Handle the response data as needed
                console.log("Response:", response.data);
                return response.data;
            } catch (error) {
                controlLoading(false);
                // Handle errors, such as network issues or server errors
                console.error("Error:", error);
                throw error; // Rethrow the error to be caught by the calling code if necessary
            }
        }
        else {
            alert("Invalid googlesheet url");
        }
    };

    return (
        <>
            <div className="dashboard-page-wrapper">
                <h1 className="dashboard-headline">Create Spreadsheet API</h1>
                <form className="create-spreadsheet-form-container" onSubmit={generateAPI}>
                    <p style={{ marginLeft: 5 }} className="dashboard-input-header">Spreadsheet URL</p>
                    <input
                        onChange={handleSheetURL}
                        value={sheetURL}
                        required
                        type="text"
                        className="url-input"
                        placeholder="Paste Google Sheets URL here"
                    />
                    {loading ? <button className="create-button">Loading...</button> : <input type="submit" value="Create" className="create-button" />}
                </form>
            </div>


        </>
    );
};


export default CreateSpreadSheetAPI;