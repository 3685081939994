import React from "react";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Constants } from "../../constants";
import Footer from "../../components/footer/Footer";

const Pricing = () => {

  const handleSignIn = async () => {
    // Redirect to backend route for Google authentication
    window.location.href = `${Constants.apiURL}/auth/google`;
  };

  return (
    <div>
      <section id="pricing" class="pricing-content section-padding">

        <div class="center-pricing-cards-grid">
          <div className="pricing-cards-grid">
            <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;*/ }}>
              <div class="pricing_design">
                <div class="single-pricing">
                  <div class="price-head">
                    <h2>Free</h2>
                    <h1>$0</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    <li><b>3</b> Spreadsheet APIs</li>
                    <li><b>400</b> Requests per month</li>
                    <li><b>100</b> Rows</li>
                    <li>Read & Write Data</li>
                  </ul>
                  <div class="pricing-price">

                  </div>
                  <a onClick={handleSignIn} class="price_btn">Get Started</a>
                </div>
              </div>
            </div>
            <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeInUp;*/ }}>
              <div class="pricing_design">
                <div class="single-pricing">
                  <div class="price-head">
                    <h2>Basic</h2>
                    <h1 class="price">$10</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    <li><b>25</b> Spreadsheet APIs</li>
                    <li><b>55,000</b> Requests per month</li>
                    <li><b>1000</b> Rows</li>
                    <li>Read & Write Data</li>
                  </ul>
                  <div class="pricing-price">

                  </div>
                  <a href="#/" class="price_btn">Get Started</a>
                </div>
              </div>
            </div>
            <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;*/ }}>
              <div class="pricing_design">
                <div class="single-pricing">
                  <div class="price-head">
                    <h2>Premium</h2>
                    <h1 class="price">$25</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    <li><b>60</b> Spreadsheet APIs</li>
                    <li><b>80,000</b> Requests per month</li>
                    <li><b>1500</b> Rows</li>
                    <li>Read & Write Data</li>
                  </ul>
                  <div class="pricing-price">

                  </div>
                  <a href="#/" class="price_btn">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </div>
  );

};

export default Pricing;
