import React from "react";
import { AuthContext } from "./contexts/AuthContextProvider";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Navbar } from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Pricing from "./pages/pricing/Pricing";
import { DashboardLayout } from "./dashboard-layout/DashboardLayout";
import { LandingPageLayout } from "./landing-page-layout/LandingPageLayout";
import { SideNav } from "./components/sidenav/SideNav";
import About from "./pages/about/About";
import Documentation from "./pages/documentation/Documentation";
import CreateSpreadSheetAPI from "./pages/create-spreadsheet-api/CreateSpreadAPI";
import Spreadsheets from "./pages/spreadsheets/Spreadsheets";
import Usage from "./pages/usage/Usage";
import { Constants } from "./constants";
import Plan from "./pages/plan/Plan";

const App = () => {
  const location = useLocation();
  const path = location.pathname;
  const { auth_dispatch } = React.useContext(AuthContext);

  const checkAuth = async () => {
    try {
      const response = await axios.get(`${Constants.apiURL}/auth/checkauth`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        auth_dispatch({
          type: "LOGIN",
        });
      } else if (response.status === 401) {
        auth_dispatch({
          type: "LOGOUT",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        auth_dispatch({
          type: "LOGOUT",
        });
      } else {
        console.log(error);
      }
    }
  };



  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  React.useEffect(() => {
    checkAuth();
  }, []);
  return (
    <>
      {isDashboardRoute ? (
        <DashboardLayout>
          <Routes>
            <Route path="/dashboard" element={<Spreadsheets />} />
            <Route path="/dashboard/add-spreadsheet" element={<CreateSpreadSheetAPI />} />
            <Route path="/dashboard/spreadsheets" element={<Spreadsheets />} />
            <Route path="/dashboard/usage" element={<Usage />} />
            <Route path="/dashboard/plan" element={<Plan />} />
          </Routes>
        </DashboardLayout>
      ) : (
        <LandingPageLayout>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/documentation" element={<Documentation />} />
          </Routes>
        </LandingPageLayout>
      )}
    </>
  );
};

/* const [sheetURL, setSheetUrl] = React.useState("");
  const { auth_state, auth_dispatch } = React.useContext(AuthContext);
  const checkAuth = async () => {
    try {
      const response = await axios.get("http://localhost:3001/auth/checkauth", {
        credentials: "include",
      });
      if (response.status === 200) {
        //return true;
        auth_dispatch({
          type: "LOGIN",
        });
      } else {
        //return false;
        auth_dispatch({
          type: "LOGOUT",
        });
      }
    } catch (error) {
      //return false;
      auth_dispatch({
        type: "LOGOUT",
      });
    }
  };

  const checkLogin = async () => {
    if (checkAuth) {
      auth_dispatch({
        type: "LOGIN",
      });
    } else {
      auth_dispatch({
        type: "LOGOUT",
      });
    }
  };

  React.useEffect(() => {
    checkAuth();
  }, []);

  const handleSheetURL = (e) => {
    setSheetUrl(e.target.value);
  };

  const handleSignIn = async () => {
    // Redirect to backend route for Google authentication
    window.location.href = "http://localhost:3001/auth/google";
  };

  const handleLogout = async () => {
    try {
      const response = await axios.get("http://localhost:3001/auth/logout", {
        credentials: "include",
      });
      if (response.status === 200) {
        auth_dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateAPI = async () => {
    const data = {
      spreadsheet_url: sheetURL,
    };
    try {
      const response = await axios.post(
        "http://localhost:3001/api/v1/generate-sheet-api",
        data,
        {
          withCredentials: true,
        }
      );

      if (response.data.upgradeMessage) {
        alert(response.data.upgradeMessage);
      }

      // Handle the response data as needed
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      // Handle errors, such as network issues or server errors
      console.error("Error:", error);
      throw error; // Rethrow the error to be caught by the calling code if necessary
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      
      {auth_state.isLoggedIn ? (
        <button>Logged in {`${auth_state.isLoggedIn}`}</button>
      ) : (
        <button onClick={handleSignIn} style={{ cursor: "pointer" }}>
          Sign in with google {`${auth_state.isLoggedIn}`}
        </button>
      )}

      <button
        onClick={handleLogout}
        style={{
          background: "red",
          color: "#fff",
          marginTop: 100,
          cursor: "pointer",
          border: "none",
        }}
      >
        Log out
      </button>

      <input
        type="url"
        onChange={handleSheetURL}
        value={sheetURL}
        style={{ width: 700, paddingTop: 10, paddingBottom: 10, marginTop: 50 }}
      />

      <button
        onClick={generateAPI}
        style={{ marginTop: 20, cursor: "pointer" }}
      >
        Generate API
      </button>
    </div>
  );
};*/

export default App;
