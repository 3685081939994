import React from "react";
import { spreadsheetsReducer } from "../reducers/SpreadSheetsReducer";

export const SpreadsheetContext = React.createContext();
const initialState = {
    spreadsheets: [],
};

const SpreadsheetContextProvider = (props) => {
    const [spreadsheet_state, spreadsheet_dispatch] = React.useReducer(
        spreadsheetsReducer,
        initialState
    );

    return (
        <SpreadsheetContext.Provider value={{ spreadsheet_state, spreadsheet_dispatch }}>
            {props.children}
        </SpreadsheetContext.Provider>
    );
};
export default SpreadsheetContextProvider;