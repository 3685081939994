import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContext from "./contexts/AuthContextProvider";
import { BrowserRouter as Router } from "react-router-dom";
import SpreadsheetContext from "./contexts/SpreadsheetsContextProvider";
import UsageContext from "./contexts/UsageContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContext>
      <SpreadsheetContext>
        <UsageContext>
          <Router>
            <App />
          </Router>
        </UsageContext>
      </SpreadsheetContext>
    </AuthContext>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
