import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <span className="footer-logo">
            <Link
              to="/"
              className="footer-link"
              style={{ fontStyle: "italic" }}
            >
              Sheetrocket
            </Link>
          </span>
          <p>Email: info@sheetrocket.com</p>
        </div>
        <div className="footer-section">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <Link to="/" className="footer-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="footer-link">
                Pricing
              </Link>
            </li>

            <li>
              <Link to="/documentation" className="footer-link">
                Documentation
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h2>Follow Us</h2>
          <p>Stay connected on social media</p>
          <div className="social-icons">
            <Icon.Instagram />
            <Icon.Linkedin />
            <Icon.GitHub />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Sheetrocket. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
