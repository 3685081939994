export const usageReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_TOTAL_REQUESTS":
            return {
                ...state,
                total_requests: action.payload,
            };
        case "FETCH_TOTAL_SHEET_APIS":
            return {
                ...state,
                total_sheet_apis: action.payload,
            };

        default:
            return state;
    }
};