import React from "react";
import "./style.css";
import example_img from "../../assets/Untitled.png";
import Fade from "react-reveal";

export const ExampleSection = () => {
  return (
    <section className="example-background">
      <div className="center-grid">
        <div className="example-grid">
          <Fade left duration={900} distance="80px">
            <>
              <div class="example-col-1">
                <img src={example_img} className="example-img" />
              </div>
            </>
          </Fade>
          <Fade right duration={900} distance="80px">
            <>
              <div className="example-col-2">
                <p>
                  <h1 class="example-title">
                    {" "}
                    POWER YOUR APPLICATIONS WITH YOUR SPREADSHEET
                  </h1>

                  <p className="example-text">
                    Sheetrocket transforms your spreadsheet into a Restful JSON
                    API, allowing you to effortlessly retrieve and update data
                    in your spreadsheet through straightforward HTTP requests
                    and URLs.
                  </p>

                  <p className="example-text">
                    Sheetrocket streamlines your application by taking care of
                    backend logic, database storage, and other non-essential
                    elements, allowing you to focus on the core aspects of your
                    application.
                  </p>

                  <p className="example-text">
                    Empower your digital menus and more by effortlessly
                    incorporating your data into your application. Sheetrocket
                    provides seamless integration, enabling you to connect with
                    your data regardless of the platform you use to develop your
                    app.
                  </p>

                  <p className="about-text"></p>
                </p>
              </div>
            </>
          </Fade>
        </div>
      </div>
    </section>
  );
};
