import React from "react";
import "./style.css";
import * as Icon from "react-feather";
import Fade from "react-reveal";

export const BenefitsSection = () => {
  return (
    <section className="benefits-background">
      <div className="center-grid">
        <div className="benefits-grid">
          <Fade left duration={800} distance="50px">
            <div className="benefits-card-col">
              <Icon.Database size={60} color="teal" style={{ fontWeight: "bold" }} />
              <h1 className="benefits-title-text">GET MORE DONE</h1>
              <p className="benefits-card-col-text">
                Transform Google Spreadsheets into a CMS, streamline form data
                capture, and cut development time significantly. Maximize
                efficiency and unleash the power of data management.
              </p>
            </div>
          </Fade>
          <Fade left duration={800} distance="50px">
            <div className="benefits-card-col">
              <Icon.Clock size={60} color="teal" style={{ fontWeight: "bold" }} />
              <h1 className="benefits-title-text">SAVE TIME</h1>
              <p className="benefits-card-col-text">
                Experience real-time updates with Sheetrocket, where your data
                stays in sync with Google Sheets, reflecting changes instantly.
                Bid farewell to manual syncing, embrace a dynamic workflow, and
                ensure your data is always up-to-date.
              </p>
            </div>
          </Fade>
          <Fade left duration={800} distance="50px">
            <div className="benefits-card-col">
              <Icon.Lock size={60} color="teal" style={{ fontWeight: "bold" }} />
              <h1 className="benefits-title-text">SECURE YOUR DATA</h1>
              <p className="benefits-card-col-text">
                Enjoy secure control over your private Google Sheets data with
                Sheetrocket. Even in private mode, access and manage your data
                securely, ensuring exclusive control and peace of mind.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
