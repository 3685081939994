/*import React from "react";
import "./style.css";

export const DashboardNavBar = () => {
  const [isOpen, setIsOpen] = React.useState(false);


  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };





  return (
    <>

      <div className="dashboard-toggle-wrapper">
        <button className="dashboard-toggle-button" onClick={toggleDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="teal"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>


  );
};
*/



import {
  Drawer
} from "@material-ui/core";
import { useState } from "react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import "./style.css";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { Constants } from "../../constants";

const data = [

  { name: "Spreadsheets", icon: <Icon.File />, routeName: "/dashboard/spreadsheets" },
  { name: "Usage", icon: <Icon.BarChart />, routeName: "/dashboard/usage" },
  { name: "Plan", icon: <Icon.CreditCard />, routeName: "/dashboard/plan" },
  { name: "Add Spreadsheet", icon: <Icon.PlusSquare />, routeName: "/dashboard/add-spreadsheet" },
];

export const DashboardNavBar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const { auth_dispatch } = React.useContext(AuthContext);
  const [loading, controlLoading] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(true);
  };






  const handleLogout = async () => {
    controlLoading(true);
    try {
      const response = await axios.get(`${Constants.apiURL}/auth/logout`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        auth_dispatch({ type: "LOGOUT" });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };







  const getList = () => (
    <div style={{ width: 250 }} onClick={() => setOpen(false)} className="navdrawer-wrapper" >
      <div className="dashboard-logo-wrapper">
        <Link to="/" className="dashboard-logo">
          Sheetrocket
        </Link>
      </div>
      <nav>
        <ul>
          {data.map((item, index) => (

            <li>
              <Link
                to={item.routeName}
                className={
                  location.pathname === `${item.routeName}`
                    ? "focused-sidenav-link"
                    : "sidenav-link"
                }
              >
                {item.icon}
                <span style={{ marginLeft: 10 }}>{item.name}</span>
              </Link>
            </li>


          ))}
        </ul>

        <div className="logout-button-wrapper" onClick={handleLogout}>
          <Icon.LogOut />
          {loading ? <span style={{ marginLeft: 5 }}>Loading...</span> : <span style={{ marginLeft: 5 }}>Log out</span>}

        </div>
      </nav>
    </div>
  );
  return (
    <div>
      <div className="dashboard-toggle-wrapper">
        <button className="dashboard-toggle-button" onClick={toggleDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="teal"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <Drawer open={open} anchor={"left"} onClose={() => setOpen(false)}>
        {getList()}
      </Drawer>
    </div>
  );
}


