import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Icon from "react-feather";
import "./style.css";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { Constants } from "../../constants";

export const SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const { auth_dispatch } = React.useContext(AuthContext);
  const [loading, controlLoading] = React.useState(false);




  const handleLogout = async () => {
    controlLoading(true);
    try {
      const response = await axios.get(`${Constants.apiURL}/auth/logout`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        auth_dispatch({ type: "LOGOUT" });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className={`side-navigation ${isOpen ? "open" : ""}`}>
      <div className="dashboard-logo-wrapper">
        <Link to="/" className="dashboard-logo">
          Sheetrocket
        </Link>
      </div>
      <nav>
        <ul>
          <li>
            <Link
              to="/dashboard/spreadsheets"
              className={
                location.pathname == "/dashboard/spreadsheets"
                  ? "focused-sidenav-link"
                  : "sidenav-link"
              }
            >
              <Icon.File />
              <span style={{ marginLeft: 10 }}>Spreadsheets</span>
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/usage"
              className={
                location.pathname == "/dashboard/usage"
                  ? "focused-sidenav-link"
                  : "sidenav-link"
              }
            >
              <Icon.BarChart />
              <span style={{ marginLeft: 10 }}>Usage</span>
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/plan"
              className={
                location.pathname == "/dashboard/plan"
                  ? "focused-sidenav-link"
                  : "sidenav-link"
              }
            >
              <Icon.CreditCard />
              <span style={{ marginLeft: 10 }}>Plan</span>
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/add-spreadsheet"
              className={
                location.pathname == "/dashboard/add-spreadsheet"
                  ? "focused-sidenav-link"
                  : "sidenav-link"
              }
            >
              <Icon.PlusSquare />
              <span style={{ marginLeft: 10 }}>Create API</span>
            </Link>
          </li>
          <div className="logout-button-wrapper" onClick={handleLogout}>
            <Icon.LogOut />
            {loading ? <span style={{ marginLeft: 5 }}>Loading...</span> : <span style={{ marginLeft: 5 }}>Log out</span>}

          </div>
        </ul>
      </nav>
    </div >
  );
};
