import React from "react";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Constants } from "../../constants";
import Footer from "../../components/footer/Footer";
import axios from "axios";

const Plan = () => {

    const [userTier, setUserTier] = React.useState(null);

    const handleSignIn = async () => {
        // Redirect to backend route for Google authentication
        window.location.href = `${Constants.apiURL}/auth/google`;
    };

    const getUsertier = async () => {

        try {
            const response = await axios.get(
                `${Constants.apiURL}/api/v1/get-user-tier`,
                {
                    withCredentials: true,
                }
            );

            // Handle the response data as needed
            setUserTier(response.data);
            console.log("Response:", response.data);

        } catch (error) {
            // Handle errors, such as network issues or server errors
            console.error("Error:", error);

        }

    };

    React.useEffect(() => {
        getUsertier();
    }, []);

    return (
        <>
            <div className="dashboard-page-wrapper">
                <h1 className="dashboard-headline">Your Plan</h1>
                <div style={{ fontSize: 20 }}>You are currently on the <b>{userTier}</b> plan</div>
            </div>
            <section id="pricing" class="pricing-content section-padding">

                <div class="dashboard-center-pricing-cards-grid">
                    <div className="pricing-cards-grid">
                        <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;*/ }}>
                            <div class="pricing_design">
                                <div class="single-pricing">
                                    <div class="price-head">
                                        <input name="input-plan-free-None" type="radio" value="" checked={userTier === "Free" ? "checked" : ""}></input>
                                        <h2>Free</h2>
                                        <h1>$0</h1>
                                        <span>/Monthly</span>
                                    </div>
                                    <ul>
                                        <li><b>3</b> Spreadsheet APIs</li>
                                        <li><b>400</b> Requests per month</li>
                                        <li><b>100</b> Rows</li>
                                        <li>Read & Write Data</li>
                                    </ul>
                                    <div class="pricing-price">

                                    </div>
                                    <a onClick={handleSignIn} class="price_btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeInUp;*/ }}>
                            <div class="pricing_design">
                                <div class="single-pricing">
                                    <div class="price-head">
                                        <input name="input-plan-free-None" type="radio" value="" checked={userTier === "Basic" ? "checked" : ""}></input>
                                        <h2>Basic</h2>
                                        <h1 class="price">$10</h1>
                                        <span>/Monthly</span>
                                    </div>
                                    <ul>
                                        <li><b>25</b> Spreadsheet APIs</li>
                                        <li><b>55,000</b> Requests per month</li>
                                        <li><b>1000</b> Rows</li>
                                        <li>Read & Write Data</li>
                                    </ul>
                                    <div class="pricing-price">

                                    </div>
                                    <a href="#/" class="price_btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="pricing-card-col" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0" style={{/*visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;*/ }}>
                            <div class="pricing_design">
                                <div class="single-pricing">
                                    <div class="price-head">
                                        <input name="input-plan-free-None" type="radio" value="" checked={userTier === "Premium" ? "checked" : ""}></input>
                                        <h2>Premium</h2>
                                        <h1 class="price">$25</h1>
                                        <span>/Monthly</span>
                                    </div>
                                    <ul>
                                        <li><b>60</b> Spreadsheet APIs</li>
                                        <li><b>80,000</b> Requests per month</li>
                                        <li><b>1500</b> Rows</li>
                                        <li>Read & Write Data</li>
                                    </ul>
                                    <div class="pricing-price">

                                    </div>
                                    <a href="#/" class="price_btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default Plan;