import React from "react";
import "./style.css";
import Footer from "../../components/footer/Footer";

const Documentation = () => {

  return (
    <>
      <div className='documentation-page-wrapper'>
        <h1 className="documentation-headline">Sheetrocket - Documentation Coming Soon!</h1>

        <div className="documentation-text-wrapper">
          <p className="documentation-text">Thank you for choosing Sheetrocket.</p>

          <p className="documentation-text">We're excited that you've chosen Sheetrocket to turn your Google Sheets into powerful JSON APIs. While the tool is fully functional, we understand the importance of having comprehensive documentation to make your experience seamless.</p>

          <div className="documentation-text-subheadline">What to expect in the upcoming documentation:</div>

          <ul className="documentation-list">
            <li>Step-by-step guides on turning your Google Sheets into APIs</li>
            <li>Explanation of advanced features and customization options</li>
            <li>Integration examples with popular programming languages</li>
            <li>Using private Google Sheets securely with Sheetrocket</li>
            <li>Displaying Google Sheet data on your website in a visually appealing way</li>
            <li>Leveraging Google Sheets as a CMS for your applications or websites</li>
            <li>Building lead generation forms and contact forms with Sheetrocket</li>
            <li>Troubleshooting tips and FAQs</li>
          </ul>


          <div className="documentation-text-subheadline">Estimated Documentation Release Date: March, 2024</div>
          <p className="documentation-text">In the meantime, for urgent questions or assistance, please reach out to our dedicated support team at <a href="mailto:support@sheetrocket.co">support@sheetrocket.co</a></p>

          <p className="documentation-text">We appreciate your patience and are eager to provide you with a comprehensive resource to unlock the full potential of Sheetrocket.</p>

          <p className="documentation-text">Thank you for being a valued member of the Sheetrocket community!</p>

          <p className="documentation-text">Sincerely,</p>

          <p className="documentation-text">Allen Jones</p>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Documentation;
