import React from "react";


export const ProgressBar = ({ bgcolor, progress, height }) => {
    const Parentdiv = {
        height: height,
        width: '90%',
        marginLeft: 10,
        backgroundColor: 'rgb(209, 203, 203)',
        borderRadius: 5,
        borderWidth: 2,
        borderColor: "black"
    };

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 5,
        textAlign: 'right'
    };

    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
    };

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
            </div>
        </div>
    );
};