import React from "react";
import "./style.css";
import sheetImg from "../../assets/googlesheet-screenshot2.PNG";
import * as Icon from "react-feather";
import Fade from "react-reveal";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
export const IllustrationSection = () => {
  const codeString = `GET   https://sheetrocket.com/api/v1/FgI6zV8qT121/characters/
  {
    Name: "Jollof Rice",
    Price: 35.85,
    Image_url: "https://example.com/static/jollof-rice.jpg ",
  },
  {
    Name: "Sandwich",
    Price: 8.0,
    Image_url: "https://example.com/static/sandwich.jpg",
  },
 ...............
];
    `;
  return (
    <section className="center-grid">
      <div className="illustration-grid">
        <Fade left duration={900} distance="80px">
          <div className="sheet-img-col">
            <img src={sheetImg} className="sheet-img" />
          </div>
        </Fade>
        <Fade left duration={900} distance="80px">
          <div className="arrow-left-icon-col">
            <Icon.ArrowRight
              className="arrow-left-icon"
              size={20}
              color="teal"
            />
          </div>
        </Fade>
        <Fade left duration={900} distance="80px">
          <div className="arrow-down-icon-col">
            <Icon.ArrowDownRight
              className="arrow-down-icon"
              size={20}
              color="teal"
            />
          </div>
        </Fade>
        <Fade right duration={900} distance="80px">
          <div className="syntax-highlighter-col">
            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              customStyle={{
                borderRadius: 10,
                backgroundColor: "#001E3C",
                height: 400,
              }}
            >
              {codeString}
            </SyntaxHighlighter>
          </div>
        </Fade>
      </div>
    </section>
  );
};
