export const spreadsheetsReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SPREADSHEETS":
            return {
                ...state,
                spreadsheets: action.payload,
            };

        default:
            return state;
    }
};