import React from "react";
import "./style.css";
import * as Icon from "react-feather";
import Fade from "react-reveal";
import { SheetsURLForm } from "../../components/sheets-url-form/SheetsURLForm";
import { Constants } from "../../constants";

export const HeroSection = () => {
  const handleSignIn = async () => {
    // Redirect to backend route for Google authentication
    window.location.href = `${Constants.apiURL}/auth/google`;
  };

  return (
    <section className="hero-section">
      <Fade top duration={800} distance="40px">
        <div className="headline-wrapper">
          <h1 className="headline">
            Convert Your Google Sheets into a JSON API
          </h1>
        </div>

        <div className="subheadline-wrapper">
          <h4 className="subheadline">
            Experience seamless integration without API keys and effortlessly
            use your Google Sheets as a CMS for your website data.
          </h4>
        </div>

        {/*<div className="sheeturl-form-wrapper">
          <SheetsURLForm />
  </div>*/}

        <div className="call-to-action-wrapper">
          <button onClick={handleSignIn} className="call-to-action-button">
            Get Started for Free
          </button>
        </div>

        <span className="no-credit-card-required-text">
          <span >Get Started for Free.</span>
          <Icon.CreditCard size={15} style={{ marginLeft: 5 }} />{" "}
          <span style={{ marginLeft: 5 }}>No credit card required.</span>
        </span>
      </Fade>
    </section>
  );
};
