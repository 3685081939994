import React from "react";
import "./style.css";
import { SideNav } from "../components/sidenav/SideNav";
import { DashboardNavBar } from "../components/dashboard-navbar/DashboardNavBar";

export const DashboardLayout = ({ children }) => {
  return (
    <div className="layout-background">
      <DashboardNavBar />
      <div className="layout-grid">
        <div className="left-column">
          <SideNav />
        </div>
        <div className="right-column">{children}</div>
      </div>
    </div>
  );
};
