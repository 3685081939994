import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import "./style.css";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { Constants } from "../../constants";

export const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [boxShadow, setBoxShadow] = useState(false);
  const { auth_state, auth_dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();



  const location = useLocation();
  const path = location.pathname;

  const toggleMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const changeNavStyle = () => {
    if (window.scrollY >= 40) {
      setBoxShadow(true);
    } else {
      setBoxShadow(false);
    }
  };

  const handleSignIn = async () => {
    // Redirect to backend route for Google authentication
    window.location.href = `${Constants.apiURL}/auth/google`;
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavStyle);
    return () => {
      window.removeEventListener("scroll", changeNavStyle);
    };
  }, []);

  return (
    <nav
      className={
        boxShadow || path == "/signup" || path == "/login"
          ? "shadow-navigation"
          : "navigation"
      }
    >
      <button className="menu-button" onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="teal"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <Link to="/" className="logo">
        Sheetrocket
      </Link>

      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>

          <li>
            <Link
              onClick={toggleMenu}
              to="/"
              className={
                location.pathname == "/"
                  ? "focused-nav-link"
                  : "nav-link"
              }
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              onClick={toggleMenu}
              to="/pricing"
              className={
                location.pathname == "/pricing"
                  ? "focused-nav-link"
                  : "nav-link"
              }
            >
              Pricing
            </Link>
          </li>



          <li>
            <Link
              onClick={toggleMenu}
              to="/documentation"
              className={
                location.pathname == "/documentation" ? "focused-nav-link" : "nav-link"
              }
            >
              Documentation
            </Link>
          </li>
          <li>
            {auth_state.isLoggedIn ? (
              <div>
                <button onClick={() => navigate("/dashboard/spreadsheets")} className="auth-button">Dashboard</button>
              </div>
            ) : (
              <div>
                <button onClick={handleSignIn} className="auth-button">
                  Sign in
                </button>
              </div>

            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};
