import React from "react";
import "./style.css";
import { ProgressBar } from "../../components/ProgressBar";
import axios from "axios";
import { UsageContext } from "../../contexts/UsageContextProvider";
import { Constants } from "../../constants";


const Usage = () => {
    const { usage_state, usage_dispatch } = React.useContext(UsageContext);


    const calculateRequestsPercentage = (numerator, denominator) => {
        const decimalValue = numerator / denominator;
        const percentage = decimalValue * 100;
        return percentage.toFixed(0);

    };

    const calculateTotalSheetsPercentage = (numerator, denominator) => {
        const decimalValue = numerator / denominator;
        const percentage = decimalValue * 100;
        return percentage.toFixed(0);

    };
    const getTotalSheetAPIs = async () => {

        try {
            const response = await axios.get(
                `${Constants.apiURL}/api/v1/total-sheet-apis-by-user`,
                {
                    withCredentials: true,
                }
            );

            // Handle the response data as needed
            console.log("Response:", response.data);
            usage_dispatch({ type: "FETCH_TOTAL_SHEET_APIS", payload: response.data });
        } catch (error) {
            // Handle errors, such as network issues or server errors
            console.error("Error:", error);

        }
    };

    const getTotalRequestsInCurrentMonth = async () => {
        try {
            const response = await axios.get(
                `${Constants.apiURL}/api/v1/retrieve-total-requests-this-month`,
                {
                    withCredentials: true,
                }
            );

            // Handle the response data as needed
            console.log("Response:", response.data);
            usage_dispatch({ type: "FETCH_TOTAL_REQUESTS", payload: response.data });
        } catch (error) {
            // Handle errors, such as network issues or server errors
            console.error("Error:", error);

        }

    };

    React.useEffect(() => {
        getTotalRequestsInCurrentMonth();
        getTotalSheetAPIs();
    }, []);
    return (
        <>
            <div className="dashboard-page-wrapper">
                <h1 className="dashboard-headline">Your Usage</h1>
                <div className="usage-list-item-wrapper">
                    <div className="usage-list-item-title-wrapper">
                        <h2 className="usage-list-item-title">Spreadsheet APIs</h2>
                    </div>
                    <ProgressBar bgcolor="teal"
                        progress={calculateTotalSheetsPercentage(usage_state.total_sheet_apis, 3)}
                        height={20} />

                    <div className="usage-message-text-wrapper">
                        You have <div style={{ width: 8 }}></div> <b> {usage_state.total_sheet_apis}/3</b> <div style={{ width: 8 }}></div> Spreadsheet APIs. ({calculateTotalSheetsPercentage(usage_state.total_sheet_apis, 3)}%)
                    </div>
                </div>

                <div className="usage-list-item-wrapper">
                    <div className="usage-list-item-title-wrapper">
                        <h2 className="usage-list-item-title">Spreadsheet API Requests</h2>
                    </div>
                    <ProgressBar bgcolor="teal"
                        progress={calculateRequestsPercentage(usage_state.total_requests, 350)}
                        height={20} />

                    <div className="usage-message-text-wrapper">
                        <div className="usage-message-text"> Current usage: <b>{usage_state.total_requests}/350</b> requests this month. ({calculateRequestsPercentage(usage_state.total_requests, 350)}%)</div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Usage;